<script>
import {
  ArrowUpIcon,
  ArrowDownIcon,
  MailIcon,
  PhoneIcon,
  MapPinIcon,
  LinkIcon, FacebookIcon, PhoneForwardedIcon, PrinterIcon, CornerRightDownIcon
} from 'vue-feather-icons';

import Vue from 'vue'
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import VueSocialSharing from 'vue-social-sharing'
import duppla from '@/duppla'

import { VueSvgGauge } from 'vue-svg-gauge'
import VueSpeedometer from "vue-speedometer"
import { setWithExpiration, getWithExpiration } from '@/services/storageResultsService';

const RESULTS_SAVE_TAG = process.env.VUE_APP_RESULTS_SAVE_TAG;


Vue.use(VueSocialSharing);

/**
 * Page-invoice component
 */
export default {
  components: {
    Navbar,
    Footer,
    ArrowUpIcon,
    ArrowDownIcon,
    MailIcon,
    PhoneIcon,
    MapPinIcon,
    LinkIcon,
    FacebookIcon,
    PhoneForwardedIcon,
    PrinterIcon,
    CornerRightDownIcon,
    VueSvgGauge, VueSpeedometer
  },
  data() {
    return {
      showScrArrow: true,
      countries: [],
      badToken: false,
      badPasted: false,
      validatingToken: false,
      token1: '',
      token2: '',
      token3: '',
      token4: '',
      token5: '',
      token6: '',
      emailChecked: false,
      showAll: true,
      canResendEmail: true,
      canResendEmailTimer: 0,
      intervalId: null,
      updating: false,
      contact_email: '',
      contact_email_update: '',
      // opt_advice: '',
      opt_insurance: '',
      opt_screening: '',
      screeningForm: {},
      // contact_phone_country: null,
      // contact_phone: '',
      form: {},
      sharing: {
        url: window.location.href.split('/').slice(0, 3).join('/') + "/?id=",
        title: '¡Reduce tu riesgo frente al cáncer!',
        description: '¿Te imaginas vivir en un país donde nadie se pierde frente al cáncer? Da el primer paso para conocer y reducir tu riesgo frente al cáncer.',
        quote: 'La detección oportuna hace la diferencia, 90% de los cánceres tratados a tiempo son curables. - Duppla',
        hashtags: 'duppla,cancer'
      },
      urlReturn: window.location.href.split('/').slice(0, 3).join('/') + "/?id=",
      urlReturnConoce: "https://www.duppla.doctor/beneficiarios",
      networks: [
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi mdi-whatsapp icon-lg', color: '#128c7e' },
        { network: 'email', name: 'Email', icon: 'mdi mdi-email icon-lg', color: '#333333' },
        { network: 'messenger', name: 'Messenger', icon: 'mdi mdi-facebook-messenger icon-lg', color: '#A334FA' }
        //{ network: 'facebook', name: 'Facebook', icon: 'mdi mdi-facebook icon-lg', color: '#1877f2' },
        //{ network: 'twitter', name: 'Twitter', icon: 'mdi mdi-twitter icon-lg', color: '#1da1f2' },
        //{ network: 'linkedin', name: 'LinkedIn', icon: 'mdi mdi-linkedin icon-lg', color: '#007bb5' },
        //{ network: 'reddit', name: 'Reddit', icon: 'mdi mdi-reddit icon-lg', color: '#ff4500' }
      ],
      result: null
      /*result: 
      
        {
        "id": "recDrfjIgnWqdNOHb",
        "createdTime": "2022-09-30T23:24:25.000Z",
        "fields": {
          "identifier": 212,
          "contact_phone": "8180158128",
          "contact_phone_country": [
            "recAJYx0yQTMWWIbB"
          ],
          "record_date": "2022-09-30T23:24:25.165Z",
          "uuid": "d81e62bc-740c-4725-b902-d5a3a6df0404",
          "contact_email": "constantino.lojero@gmail.com",
          "patient_sex": "male",
          "user_age": "40",
          "symptom_concern": "no",
          "smoke": "no",
          "alcohol": "no",
          "exercise": "no",
          "eating_habit": "normal",
          "direct_relative": "idk",
          "weight": "65",
          "height": "176",
          "diabetes": "no",
          "hiperlipidemia": "no",
          "organization": [
            "recdpHQNUxZK2aVsV"
          ],
          "imc": 20.98,
          "smoking_index": 0,
          "general_base_rate": 0.00704682752815692,
          "helper_exercise_factor": 0,
          "helper_imc_factor": 0,
          "mama_ahf_factor": 0,
          "mama_ago_factor": 0,
          "mama_habits_factor": 0,
          "mama_factor": 0,
          "colon_ahf_factor": 0,
          "colon_habits_factor": 0,
          "colon_diab_factor": 0,
          "colon_hiperlip_factor": 0,
          "colon_factor": 0,
          "pulmon_factor": 0,
          "prostata_factor": 0,
          "prostata_ahf_factor": 0,
          "general_factor": 0,
          "general_rate": 0.00704682752815692,
          "mama_base_rate": 0,
          "mama_rate": 0,
          "colon_base_rate": 0.00092344062815955,
          "colon_rate": 0.00092344062815955,
          "pulmon_base_rate": 0.000297934902845353,
          "pulmon_rate": 0.000297934902845353,
          "prostata_base_rate": 0.000467920629840807,
          "prostata_rate": 0.000467920629840807,
          "menopause": "no",
          "general_base_rate_h": "7 de cada 1000",
          "pulmon_rate_f": "0%",
          "general_rate_h": "7 de cada 1000",
          "general_rate_min": 0.00450715,
          "general_rate_min_h": "1 de 2000",
          "general_rate_max": 0.04087160,
          "general_rate_max_h": "1 de 20",
          "contact_phone_country_iso": [
            "MX"
          ],
          "organization_name": [
            "Coca Cola Company"
          ],
          "messages": {
            "intro": [
              {
                "identifier": 5,
                "id": "CG_Factor_Riesgo_Masculino",
                "text": "7 de cada 1000 hombres con tu perfil de riesgo, serán diagnosticados con cáncer en los próximos 5 años. ",
                "status": "active",
                "category": "intro",
                "order": 5,
                "color": "primary"
              }
            ],
            "intro2": [
              {
                "identifier": 3,
                "id": "SINTOMAS_Positivo",
                "text": "IMPORTANTE: este cuestionario calcula el riesgo de personas sin síntomas ni preexistencia de cáncer. Dado que reportaste algunos síntomas, te sugerimos que consultes a un especialista, o te pongas en contacto con nosotros vía Onco.Chat",
                "status": "active",
                "category": "intro",
                "order": 3,
                "color": "info"
              }
            ],
            "factores_modificables": [
              {
                "identifier": 14,
                "id": "FM_Negativo",
                "text": "Ninguno. Con base en tus respuestas, llevas un estilo de vida saludable. ¡Sigue así para cuidar tu salud! ",
                "status": "active",
                "category": "factores_modificables",
                "order": 7,
                "color": "success"
              }
            ],
            "factores_no_modificables": [
              {
                "identifier": 22,
                "id": "Factor_NM_Edad",
                "text": "Conforme más experiencia adquirimos (más edad) las probabilidades de riego aumenta. Entre mayores seamos, más importantes son los esfuerzos de tamizaje que te explicaremos más adelante.",
                "status": "active",
                "category": "factores_no_modificables",
                "order": 5,
                "color": "warning"
              },
              {
                "identifier": 23,
                "id": "Factor_NM_AHF_Nose",
                "text": "El factor hereditario (presencia de cáncer en familia) nos ayuda a determinar con más presición el riesgo de padecer un cáncer. Te sugerimos que tengas conversaciones con tu familia en torno al tema, y -en caso de que descubras que ha habido presencia de cáncer en familiares inmediatos (padres o hermanos) o bien secundarios (primos, tíos, abuelos) CALL TO ACTION",
                "status": "active",
                "category": "factores_no_modificables",
                "order": 6,
                "color": "info"
              }
            ],
            "tamizaje":[
              {
                "identifier": 22,
                "id": "Tamizaje_Colon_Edad_45+",
                "field_name": "scr_fit",
                "screening_question": "¿Te has realizado el examen de sangre oculta en heces (método fit) en el último año?",
                "text_yes":"Felicidades Colon",
                "text": "A partir de los 45 años es recomendable iniciar con una detección temprana de cáncer de colon mediante una prueba de sangre oculta en heces una vez al año.",
                "status": "active",
                "category": "tamizaje",
                "order": 1,
                "color": "warning"
              },
              {
                "identifier": 22,
                "id": "Tamizaje_Fuma_20+50",
                "field_name": "scr_tctorax",
                "screening_question": "¿Te has realizado una tomografía (TAC) de tórax en el último año?",
                "text_yes":"Felicidades Torax",
                "text": "Dado tu perfil de edad y historial de consumo de tabaco es importante realizarte un estudio de TAC de tórax de baja dosis una vez al año. Esta práctica reduce el riesgo de muerte por cáncer de pulmón.",
                "status": "active",
                "category": "tamizaje",
                "order": 2,
                "color": "warning"
              }
            ]
          }
        }
      }/**/

    }
  },
  props: {
    param: Object
  },
  computed: {
    token() {
      return this.token1 + this.token2 + this.token3 + this.token4 + this.token5 + this.token6
    },
    reqEmailValidation() {
      return this.result.fields.organization_req_email_validation
    },
    sMinValue() {
      return this.result.fields.general_rate_min * 0.6
    },
    sMaxValue() {
      return this.result.fields.general_rate_max * 1.05
    },
    sValue() {
      return this.result.fields.general_rate
    },
    sCustomSegment() {
      let segments = [
        this.sMinValue,
        this.sMinValue + (this.sMaxValue - this.sMinValue) / 4.5,
        this.sMaxValue - (this.sMaxValue - this.sMinValue) / 3,
        this.sMaxValue
      ]
      return segments
    },
    sCustomSegmentMessage() {
      let segments
      if (Number(this.result.fields.user_age) <= 39) {
        segments = [
          { color: "#2eca8b", textColor: "#ffffff", label: this.$t('result_screen.segments.under_40.green.label'), message: this.$t('result_screen.segments.under_40.green.message') },
          { color: "#ffc107", textColor: "black", label: this.$t('result_screen.segments.under_40.yellow.label'), message: this.$t('result_screen.segments.under_40.yellow.message') },
          { color: "#e43f52", textColor: "#ffffff", label: this.$t('result_screen.segments.under_40.red.label'), message: this.$t('result_screen.segments.under_40.red.message') }
        ]
      } else {
        segments = [
          { color: "#ffc107", textColor: "black", label: this.$t('result_screen.segments.over_40.yellow.label'), message: this.$t('result_screen.segments.over_40.yellow.message') },
          { color: "#f17425", textColor: "#ffffff", label: this.$t('result_screen.segments.over_40.orange.label'), message: this.$t('result_screen.segments.over_40.orange.message') },
          { color: "#e43f52", textColor: "#ffffff", label: this.$t('result_screen.segments.over_40.red.label'), message: this.$t('result_screen.segments.over_40.red.message') }
        ]
      }
      return segments
    },
    sCustomSegmentColor() {
      let colors = []
      for (var i = 0; i < this.sCustomSegmentMessage.length; i++) {
        colors.push(this.sCustomSegmentMessage[i].color)
      }
      //return ["#28a745", "#ffc107", "#dc3545"]
      return colors
    },
    scrCompleted() {
      for (let i = 0; i < this.result.fields.messages.tamizaje.length; i++) {
        if (!this.result.fields.messages.tamizaje[i].screening_answer) {
          return false
        }
      }
      return true
    },
    scrYesAll() {
      for (let i = 0; i < this.result.fields.messages.tamizaje.length; i++) {
        if (this.result.fields.messages.tamizaje[i].screening_answer === 'no') {
          return false
        }
      }
      return true
    }
  },
  methods: {
    showTamizaje(index) {
      if (index === 0) {
        return true
      } else {
        if (this.result.fields.messages.tamizaje[index - 1].screening_answer) {
          return true
        }
      }

      // if this.result.fields.messages.tamizaje[index]
    },
    /*
    validateUuid() {
      duppla({ url: 'validateUuid?uuid=' + this.uuid, method: 'GET', data: null })
        .then(response => {
          console.log("ok: " + response.data.contact_firstname)
          this.userName = response.data.contact_firstname
          this.showContent = true
          setTimeout(() => {this.$refs.firstToken.focus()}, 100) 
        })
        .catch(error => {
          console.log(error)
          this.$router.push('/404')
        })
        .finally(() => {})
    },*/
    getCountries() {
      duppla({ url: 'countries', method: 'GET' })
        .then(response => {
          this.countries = response.data
        })
    },
    async sigin() {
      if (!this.validatingToken) {
        if (this.$refs.emailcheck.checkValidity()) {
          this.validatingToken = true
          this.badToken = false
          this.badPasted = false

          const delay = ms => new Promise(res => setTimeout(res, ms));
          //await delay(1000);



          duppla({ url: 'questionnaire/validateEmail', method: 'PATCH', data: { token: this.token } })
            .then(response => {
              // console.log("*******************************************************************")
              // console.log("Validado: " + JSON.stringify(response.data, null, 2))
              this.result = response.data
              this.emailChecked = true

              setTimeout(() => { this.goto('emailchecked') }, 100)

            })
            .catch(error => {
              let httpStatus = error.response.status.toString()
              console.error("Ha ocurrido un error inesperado: " + httpStatus)
              switch (error.response.status) {
                case 422:
                  this.token1 = ''
                  this.token2 = ''
                  this.token3 = ''
                  this.token4 = ''
                  this.token5 = ''
                  this.token6 = ''
                  this.badToken = true
                  this.$refs.firstToken.focus()
                  break
                default:
                  this.token1 = ''
                  this.token2 = ''
                  this.token3 = ''
                  this.token4 = ''
                  this.token5 = ''
                  this.token6 = ''
                  this.badToken = true
                  this.$refs.firstToken.focus()
                  this.gTrackException('questionnaire-result -> sigin: ' + error, true)
              }
            })
            .finally(() => {
              this.validatingToken = false
            })

          /*
          if (this.token === "123456"){
            this.emailChecked = true
            this.validatingToken = false
            
            setTimeout(() => {this.goto('emailchecked')}, 100) 
          } else {
            this.validatingToken = false
            
            this.token1 = ''
            this.token2 = ''
            this.token3 = ''
            this.token4 = ''
            this.token5 = ''
            this.token6 = ''
            // this.token = ''
            this.badToken = true
            this.$refs.firstToken.focus()
          }
          */
        } else {
          this.$refs.emailcheck.reportValidity()
        }
      }
    },

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="number"]'))
      const index = inputs.indexOf(e.target)
      if (index < inputs.length - 1 && e.data) {
        inputs[index + 1].focus();
        inputs[index + 1].select();
      } else {
        //console.log("enviando a validar")
        this.sigin()
      }
    },
    onPaste(evt) {
      // console.log("pasted: " + evt.clipboardData.getData('text/plain'))
      this.badPasted = false
      let pasted = evt.clipboardData.getData('text/plain').trim()
      if (pasted.length !== 6 || isNaN(pasted)) {
        this.badPasted = true
      } else {
        let arrPasted = pasted.split("")
        this.token1 = arrPasted[0]
        this.token2 = arrPasted[1]
        this.token3 = arrPasted[2]
        this.token4 = arrPasted[3]
        this.token5 = arrPasted[4]
        this.token6 = arrPasted[5]
        setTimeout(() => { this.sigin() }, 100)
      }
    },
    goto(refName) {
      // console.log('goto: ' + refName)
      // console.log(this.$refs)
      var element = this.$refs[refName];
      // console.log(element)
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    copyUrl() {
      navigator.clipboard.writeText(this.sharing.url).then(() => {
        this.$refs['copyModal'].show()
      }, () => {
        this.gTrackException('questionnaire-result -> copyUrl: ' + error)
      }
      );
    },
    reSendEmail() {
      this.manageReSendEmailTimer(60)
      duppla({ url: 'questionnaire/resendEmail', method: 'POST' })
        .catch(error => {
          this.gTrackException('questionnaire-result -> reSendEmail: ' + error)
        })
    },
    update(data) {
      return new Promise((resolve, reject) => {
        this.updating = true
        //console.log('Actualizando: ' + JSON.stringify(data))
        duppla({ url: 'questionnaire', method: 'PATCH', data: data })
          .then(response => {
            //console.log("Actualizado: " + JSON.stringify(response.data, null, 2))
            resolve(response.data)
          })
          .catch(error => {
            this.gTrackException('questionnaire-result -> update: ' + error)
            reject(error)
          })
          .finally(() => {
            this.updating = false
            this.$forceUpdate();
          })
      })
    },
    screeningAnswer(e, message, index) {
      /*
      var element = this.$refs['tamizaje' + index]
      var top = element[0].offsetTop;
      window.scrollTo(0, top);
      */
      let data = {}
      data[message.field_name] = e.target.value
      setWithExpiration(message.field_name, e.target.value)
      this.update(data)
    },
    updateProp(e) {
      let data = {}
      if (e.target.attributes.getNamedItem('at-type')) {
        if (e.target.attributes.getNamedItem('at-type').value === 'lookup') {
          data[e.target.name] = [e.target.value]
        } else {
          data[e.target.name] = e.target.value
        }
      } else {
        data[e.target.name] = e.target.value
      }
      this.update(data)
      /*
      if (e.target.name === 'opt_advice' && e.target.value === 'yes') {
        this.showAll = false
      }
      */
    },
    hideAll() {

    },
    updateEmail() {
      let form = this.$refs['emailForm']
      let data = { contact_email: this.contact_email_update }
      // console.log(form)

      if (form.checkValidity()) {
        this.update(data).then((response) => {
          this.reSendEmail()
          this.contact_email = this.contact_email_update
          this.$refs['updateEmailModal'].hide()
          this.contact_email_update = ''
        }).finally(() => { })
      } else {
        form.reportValidity()
      }
    },
    manageReSendEmailTimer(time) {
      this.canResendEmail = false
      this.canResendEmailTimer = time
      var self = this
      this.intervalId = setInterval(function () {
        //console.log('contando: ' + self.canResendEmailTimer)
        if (self.canResendEmailTimer >= 1) {
          self.canResendEmailTimer--
        } else {
          self.canResendEmail = true
          clearInterval(self.intervalId)
        }
      }, 1000);
    },
    timerScrArrow() {
      // this.showScrArd-flex-col = !this.showScrArrow
      return null
    },
    print() {
      window.print()
    }
    /*
    showAllCheck(){
      if (this.$refs.form1.checkValidity()) {
        this.showAll = true
        this.update({contact_phone: this.contact_phone})
        setTimeout(() => {this.goto('tamizaje')}, 100) 
      } else {
        this.$refs.form1.reportValidity()
      }

    } */

  },
  created() {
    /**/
    if (!this.param) {
      const result = getWithExpiration(RESULTS_SAVE_TAG);
      if (result) {
        this.$router.push('/')
      } else {
        window.location.assign("https://www.duppla.doctor/beneficiarios");
      }
    }
    this.result = this.param

    // Permitir que se vea toda la etiqueta en el widget
    /*
    if (this.result.fields.general_rate_min_h) {
      this.result.fields.general_rate_min_h = '\t' + this.result.fields.general_rate_min_h
    }
    */

    /**/
    this.urlReturn += this.result.fields.organization_uuid
    this.sharing.url += this.result.fields.organization_uuid
    this.sharing.url += '&o=' + this.result.id
    if (this.result.fields.reshare_level || this.result.fields.reshare_level === 0) {
      if (!isNaN(this.result.fields.reshare_level)) {
        // console.log('this.result.fields.reshare_level is number')
        let reshareLevel = Number(this.result.fields.reshare_level) + 1
        this.sharing.url += '&dl=' + reshareLevel
      }
    }

    duppla.defaults.headers.common['Authorization'] = 'Bearer ' + this.result.jwt

    this.getCountries()
    this.contact_email = this.result.fields.contact_email
    this.result.fields.messages.tamizaje.forEach((element, index) => {
      const savedValue = getWithExpiration(element.field_name)
      if (savedValue) {
        element.screening_answer = savedValue
      }
    })
  },
  mounted() {
    this.timer = setInterval(() => this.timerScrArrow(), 700)
  },
  unmounted() {
    // console.log("Questionnaire result unmounted!")
  },
  destroyed() {
    clearInterval(this.timer)
  }

}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
}


legend,
label {
  font-size: 1rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0
}

.buttons-end--result {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: fit-content;
  align-items: center;
}

.mail__icon--update {
  position: absolute;
  top: 1rem;
  left: 1rem;
}
</style>

<template>
  <div style="background-color: #f8f9fc;">
    <Navbar :isIcons="false" :navOptions="false" :signupOptions="false" :corporateOptions="false" />

    <!-- Invoice Start -->
    <section class="container--forms container" v-if="result">
      <div class="container--forms--result" ref="init">
        <div class="col-lg-10 card shadow rounded border-0">
          <div class="d-flex-col justify-content-center">
            <div class="mt-4 mb-4" style="width: 100%; font-weight: 600;">
              <span> {{ $t('result_screen.well_done') + " " +
                $t('result_screen.prevention_step') }}</span>
              <br />
              <span v-t="'result_screen.part_of_group'"></span><span class="text-primary">.</span>
              <br /><br />
              <span v-t="'result_screen.continue_recommendations'"></span><span class="text-primary">.</span>&nbsp;
              <arrow-up-icon class="arrow-down"></arrow-up-icon>
            </div>
          </div>

          <div>
            <div class="d-flex-col justify-content-center border-top">
              <div class="col-md-9 mt-4">
                <h6 v-t="'result_screen.your_result_is'"></h6>
              </div>
            </div>

            <div class="d-flex-col justify-content-center" v-for="message in result.fields.messages.intro">
              <div class="col-md-9 text-justify">
                <div class="alert" :class="'alert-' + message.color" role="alert">
                  <h6>{{ message.text }}</h6>
                </div>
              </div>
            </div>

            <div class="d-flex-col mt-3 justify-content-center">
              <div class="col-12 text-center">
                <vue-speedometer :forceRender="true" :width="290" :height="220" :minValue="sMinValue"
                  :maxValue="sMaxValue" :value="sValue" :ringWidth="20" :customSegmentStops="sCustomSegment"
                  :segmentColors="sCustomSegmentColor" :customSegmentLabels='[
                    {
                      text: "",
                      position: "OUTSIDE",
                      color: "#808B96"
                    },
                    {
                      text: "",
                      position: "OUTSIDE",
                      color: "#808B96"
                    },
                    {
                      text: "",
                      position: "OUTSIDE",
                      color: "#808B96"
                    },
                  ]' needleColor="#808B96" :needleHeightRatio="0.8" :currentValueText="result.fields.general_rate_h"
                  valueTextFontWeight="bold" valueTextFontSize="18px" labelFontSize="13px" />
              </div>
            </div>
            <div class="d-flex-col justify-content-center">
              <div class="col-md-9">
                <h6 v-t="'result_screen.what_does_it_mean'"></h6>
              </div>

              <div class="col-md-9">
                <p>
                <ul>
                  <li v-for="segment in sCustomSegmentMessage"><span class="badge"
                      :style="'background-color: ' + segment.color + ' !important; color: ' + segment.textColor">
                      {{ segment.label }}: </span> {{ segment.message }}</li>
                </ul>
                </p>
              </div>
            </div>
            <div class="d-flex-col mt-3 justify-content-center" v-for="message in result.fields.messages.intro2">
              <div class="col-md-9 text-justify">
                <div class="alert" :class="'alert-' + message.color" role="alert">
                  <h6>{{ message.text }}</h6>
                </div>
              </div>
            </div>
            <div class="d-flex-col mt-3 border-top justify-content-center"
              v-if="!emailChecked && reqEmailValidation === 'yes'">
              <div class="col-md-9 mt-3 text-center">
                <h6 v-t="'result_screen.want_to_know_more'"></h6>
              </div>
              <div class="col-md-9 mt-3 text-justify">
                <p v-t="'result_screen.enter_code'"></p>
              </div>
            </div>

            <div class="d-flex-col justify-content-center mb-4" v-if="!emailChecked && reqEmailValidation === 'yes'">
              <form class="mt-4 col-md-9 text-center form__update_mail--container" ref="emailcheck">

                <div class="position-relative justify-content-center" style="display: flex; text-align: center;">

                  <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token1"
                    @input="focusNext" @paste.prevent="onPaste" ref="firstToken" size="lg" autocomplete="off"
                    style="width: 40px; height:50px;" />
                  <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token2"
                    @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;" />
                  <input type="number" class="form-control align-items-center mr-2 mr-md-4" required v-model="token3"
                    @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;" />

                  <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token4"
                    @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;" />
                  <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token5"
                    @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;" />
                  <input type="number" class="form-control align-items-center mr-1 mr-md-2" required v-model="token6"
                    @input="focusNext" @paste.prevent="onPaste" autocomplete="off" style="width: 40px; height:50px;" />
                </div>

                <div class="text-center" v-if="validatingToken">
                  <b-spinner style="width: 3rem; height: 3rem;" variant="primary"
                    :label="$t('emailCheck.validating_label')">
                  </b-spinner>
                </div>

                <div class="text-center text-muted" v-if="!validatingToken">
                  {{ $t('emailCheck.copy_and_paste_prompt') }}
                </div>

                <div v-if="badToken">
                  <div class="alert alert-danger text-center" role="alert">
                    {{ $t('emailCheck.incorrect_code_alert') }}
                  </div>
                </div>

                <div v-if="badPasted">
                  <div class="alert alert-warning text-center" role="alert">
                    {{ $t('emailCheck.ensure_correct_code_before_paste') }}
                  </div>
                </div>

                <div v-if="!validatingToken">
                  <span v-if="canResendEmail">
                    {{ $t('emailCheck.did_not_receive_email') }}&nbsp;
                  </span>
                  <a href="javascript:void()" class="text-warning" v-if="canResendEmail" @click.prevent="reSendEmail">
                    {{ $t('emailCheck.click_to_resend') }}
                  </a>
                  <span class="text-info" v-if="!canResendEmail">
                    {{ $t('emailCheck.can_resend_in_seconds', { seconds: canResendEmailTimer }) }}
                  </span>
                </div>

                <div v-if="!canResendEmail && !validatingToken">
                  {{ $t('emailCheck.email_sent_to_check_spam', { email: contact_email }) }}
                </div>

                <div v-if="canResendEmail && !validatingToken">
                  {{ $t('emailCheck.is_email_incorrect', { email: contact_email }) }}<br />
                  <a href="javascript:void(0)" class="text-warning" v-b-modal.updateEmailModal>
                    {{ $t('emailCheck.click_here_to_update') }}
                  </a>
                </div>
              </form>
            </div>


            <span v-if="emailChecked || reqEmailValidation !== 'yes'">
              <div class="d-flex-col mt-3 border-top justify-content-center" ref="emailchecked" v-if="emailChecked">
                <div class="col-md-10 mt-3 mb-3 text-center">
                  <h3>{{ $t('accountValidation.thank_you_message') }} &nbsp;<i
                      class="mdi mdi-check-circle text-success"></i></h3>
                </div>

              </div>
              <div class="d-flex-col pt-3 border-top justify-content-center" ref="tamizaje">
                <div class="result-title">
                  <span v-t="'result_screen.prevention_plan_title'"></span>
                  <i class="mdi mdi-medical-bag text-primary"></i>
                </div>
                <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length > 0">
                  <p v-t="'result_screen.important_consideration'"></p>
                </div>
                <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length === 0">
                  <p>
                    <span v-t="'result_screen.good_news'"></span><br /><br />
                    <span v-t="'result_screen.no_tests_needed'"></span>
                    <br /><br />
                    <span v-t="'result_screen.cancer_symptoms_warning'"></span>
                    <br /><br />
                    <span v-t="'result_screen.advisory_offer'"></span> <a href="http://onco.chat/"
                      target="_blank">http://onco.chat/</a>
                  </p>
                </div>
              </div>

              <div class="d-flex-col mt-3 justify-content-center"
                v-for="(message, index) in result.fields.messages.tamizaje">
                <div class="col-md-9 text-justify tamizaje" v-if="showTamizaje(index)" :ref="'tamizaje' + index">
                  <label>{{ message.text_prev }}</label>
                  <label>{{ message.screening_question }}</label>
                  <div class="options--checkbox">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="index + 'Yes'" :name="'screening' + index" class="custom-control-input"
                        value="yes" required v-model="result.fields.messages.tamizaje[index].screening_answer"
                        @input="screeningAnswer($event, message, index)" />
                      <label class="custom-control-label" :for="index + 'Yes'" v-t="'result_screen.yes'">
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" :id="index + 'No'" :name="'screening' + index" class="custom-control-input"
                        value="no" required v-model="result.fields.messages.tamizaje[index].screening_answer"
                        @input="screeningAnswer($event, message, index)" />
                      <label class="custom-control-label" :for="index + 'No'" v-t="'result_screen.no'">
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-9 text-justify answer-tests"
                  v-show="result.fields.messages.tamizaje[index].screening_answer === 'yes'">
                  <div class="alert" :class="'alert-success'" role="alert">
                    <h6>
                      {{ message.text_yes }}
                    </h6>
                  </div>
                </div>
                <div class="col-md-9 text-justify answer-tests"
                  v-show="result.fields.messages.tamizaje[index].screening_answer === 'no'">
                  <div class="alert" :class="'alert-' + message.color" role="alert">
                    <h6>
                      {{ message.text }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="d-flex-col justify-content-center"
                v-if="!scrCompleted && result.fields.messages.tamizaje.length > 0" style="height: 90px;">
                <div class="col-md-9 text-center">
                  <small class="text-muted" v-t="'result_screen.answer_questions_continue'"></small>
                </div>
                <div class="col-md-9 text-center">
                  <transition name="fade">
                    <span v-if="showScrArrow" class="to-continue-arrow">
                      <arrow-down-icon></arrow-down-icon>
                    </span>
                  </transition>
                </div>
              </div>

              <div class="d-flex-col mt-3 mb-4 justify-content-center" v-if="scrCompleted">
                <div class="col-md-9 text-justify" v-if="result.fields.messages.tamizaje.length > 0">
                  <p v-t="'result_screen.risk_factors_based_on_program'"></p>
                </div>
              </div>
              <span v-if="scrCompleted">
                <div class="border-top">
                  <div class="d-flex-col mt-3 mb-4 justify-content-center">
                    <div class="result-title">
                      <span v-t="'result_screen.reduce_your_risk'"></span>
                      <i class="mdi mdi-weight-lifter text-info"></i>
                    </div>
                    <div class="col-md-9 mt-3 text-justify"
                      v-if="result.fields.messages.factores_modificables.length > 0">
                      <h6 v-t="'result_screen.actions_to_reduce_risk'"></h6>
                    </div>
                    <div class="col-md-9 text-justify" v-if="result.fields.messages.factores_modificables.length === 0">
                      <h6 v-t="'result_screen.healthy_lifestyle'"></h6>
                    </div>
                  </div>
                </div>
                <div class="d-flex-col justify-content-center"
                  v-for="message in result.fields.messages.factores_modificables">
                  <div class="col-md-9 text-justify">
                    <div class="alert" :class="'alert-' + message.color" role="alert">
                      <h6>{{ message.text }}</h6>
                    </div>
                  </div>
                </div>
                <div class="d-flex-col mt-5 border-top justify-content-center">
                  <div class="col-md-9 mt-3 text-justify">
                    <h6 v-t="'result_screen.non_modifiable_risk_factors'"></h6>
                  </div>
                </div>
                <div class="d-flex-col justify-content-center"
                  v-for="message in result.fields.messages.factores_no_modificables">
                  <div class="col-md-9 text-justify">
                    <div class="alert" :class="'alert-' + message.color" role="alert">
                      <h6>{{ message.text }}</h6>
                    </div>
                  </div>
                </div>
                <div class="d-flex-col justify-content-center mt-3 mb-5"
                  v-if="result.fields.organization_reshare === 'yes'">
                  <div class="col-md-9 mt-3 text-justify" v-t="'result_screen.help_prevent_cancer'"></div>
                  <div class="col-md-9 mt-4">
                    <div class="row">
                      <div class="col-md-6 mt-2 text-center">
                        <b-btn class="btn btn-md" @click.prevent="copyUrl"
                          style="background-color: #7D7667; padding-top: 3px; padding-bottom: 3px; width: 170px; text-align: start;">
                          <i class="mdi mdi-content-copy icon-lg" style="color: white; font-size: x-large;">
                          </i>&nbsp;
                          <span style="color: white;" v-t="'result_screen.copy_url'"></span>
                        </b-btn>
                      </div>
                      <div class="col-md-6 mt-2 text-center" v-for="network in networks">
                        <ShareNetwork :network="network.network" :url="sharing.url" :title="sharing.title"
                          :description="sharing.description" :quote="sharing.quote" :hashtags="sharing.hashtags"
                          :popup="{ width: 828, height: 626 }">
                          <span class="btn btn-md" :style="'background-color:' + network.color + ';'"
                            style="padding-top: 3px; padding-bottom: 3px; width: 170px; text-align: start;">
                            <i :class="network.icon" style="color: white; font-size: x-large;"> </i>&nbsp;
                            <span style="color: white;">{{ network.name }}</span>
                          </span>
                        </ShareNetwork>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex-col mt-3 mb-4 border-top justify-content-center">
                  <div class="col-md-9 pt-3 buttons-end--result">
                    <p class="text-justify" style="font-weight: 600;" v-t="'result_screen.life_or_death_difference'"></p>
                    <div class="buttons-end--result">
                      <b-btn @click.prevent="print" class="btn btn-primary">
                        <printer-icon class="fea icon-sm icons"></printer-icon> <span
                          v-t="'result_screen.print_report'"></span>
                      </b-btn>
                      <a class="btn btn-primary" :href="urlReturnConoce" target="_blank"
                        v-t="'result_screen.know_your_benefit'"></a>
                    </div>
                  </div>
                </div>
              </span>

            </span>
          </div>

          <div class="border-top">
            <div class="row">
              <div class="col-sm-6 mt-4 mb-4">
                <div class="text-sm-left text-muted text-center">
                  <h6 class="mb-0">
                    <span v-t="'result_screen.contact_us_at'"></span>
                    <a href="mailto:asesor@duppla.doctor" class="text-warning"> asesor@duppla.doctor</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>

    <b-modal ref="updateEmailModal" id="updateEmailModal" :title="$t('result_screen.update_email_title')" centered>
      <div class="bg-white p-3 rounded box-shadow">
        <form ref="emailForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>
                  <span v-t="'result_screen.email_label'"></span> <span class="text-danger"> *</span>
                </label>
                <div class="position-relative">
                  <mail-icon class="fea icon-sm icons mail__icon--update"></mail-icon>
                  <input type="email" class="form-control pl-5" placeholder="Email" v-model="contact_email_update"
                    required />
                </div>
              </div>
            </div>
          </div>
        </form>
        <p class="text-muted mb-0" v-t="'result_screen.update_email_message'"></p>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-btn @click="cancel()" class="btn btn-secondary" :disabled="updating">
          <span v-t="'result_screen.cancel_button'"></span>
        </b-btn>
        <b-btn @click.prevent="updateEmail" class="btn btn-primary" :disabled="updating">
          {{ (updating ? $t('result_screen.updating_button') : $t('result_screen.update_button')) }}
        </b-btn>
      </template>
    </b-modal>

    <b-modal ref="copyModal" id="copyModal" :title="$t('result_screen.share_title')" centered>
      <div class="bg-white p-3 rounded box-shadow">
        <p class="text-muted mb-0" v-t="'result_screen.url_copied_message'"></p>
      </div>
    </b-modal>

  </div>
</template>
